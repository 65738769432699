const BackToTop = () => {
    return (
        <>
            <div className="back-to-top">
                <a href="#top">🔺 Back to top</a>
            </div>
        </>
    );
};

export default BackToTop;
